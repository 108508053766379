import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { ErrorHandler } from "../../ui/ErrorHandler";
import TextEditor from "../../ui/TextEditor";
import "./Product.css";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SelectSearch from 'react-select-search';
import Select from 'react-select';
import AttributeSelector from "../../components/AttributeSelector";




const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});




function AddProduct() {

    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [attributeSets, setAttributeSets] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);

    const [categories, setCategories] = useState([]);
    const [brandId, setBrandId] = useState(0);
    const [productTypeId, setProductTypeId] = useState(0);
    const [attributeSetId, setAttributeSetId] = useState(0);
    const [categoryId, setCategoryId] = useState([]);
    const [parentId, setParentId] = useState(0);
    const [name, setName] = useState('');
    const [nameAr, setNameAr] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [shortDescriptionAr, setShortDescriptionAr] = useState('');
    const [price, setPrice] = useState(0);
    const [specialPrice, setSpecialPrice] = useState(0);
    const [qty, setQty] = useState(0);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [images, setImages] = useState([]);
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(categoryId.map(item => item.value).join(','));
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("name_ar", nameAr);
        formdata.append("price", price);
        formdata.append("special_price", specialPrice);
        formdata.append("qty", qty);
        formdata.append("description", description);
        formdata.append("description_ar", descriptionAr);
        formdata.append("short_description", shortDescription);
        formdata.append("short_description_ar", shortDescriptionAr);
        formdata.append("parent_id", parentId);
        formdata.append("brand_id", brandId);
        formdata.append("selected_attributes", JSON.stringify(selectedAttributes));
        //formdata.append("category_ids", JSON.stringify(categoryId));
        formdata.append("category_ids", categoryId.map(item => item.value).join(','));
        formdata.append("product_type_id", productTypeId);
        formdata.append("attribute_set_id", attributeSetId);
        formdata.append("meta_title", metaTitle);
        formdata.append("meta_description", metaDescription);
        formdata.append("meta_keyword", metaKeyword);
        formdata.append("store_id", 1);
        formdata.append("lang_id", 1);
        for (const img of images) {
            formdata.append('images[]', dataURItoBlob(img));
        }
        await axios("admin/product_save", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };



    const getBrands = () => {
        axios("admin/brand_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setBrands(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };


    const getProductTypes = () => {
        axios("admin/product_type_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setProductTypes(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };



    const getAttributeSets = () => {
        axios("admin/attribute_set_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAttributeSets(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };

    const handleChangeAttributeSet = (e) => {
        setAttributeSetId(e);
        let formdata = new FormData();
        formdata.append("attribute_set_id", e);
        axios("admin/attribute_by_attribute_set_id", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAttributes(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };




    const getCategories = () => {
        axios("admin/category_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setCategories(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };

    const removeElement = (index) => {
        setAttributes(prevItems => prevItems.filter((item, i) => i !== index));
        const newAttributes = { ...selectedAttributes };
        delete newAttributes[attributes[index].id];
        setSelectedAttributes(newAttributes);

    }
    const addElement = () => {
        const newData = [...attributes];
        newData.push({ id: 0, name: '', checked: 0, attribute_values: [] });
        setAttributes(newData);
    }

    const handleAttributeSelect = (attributeId, value) => {
        setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: value
        });
    };

    const handleUpdateAttributeValues = (index, attributeValues) => {
        setAttributes(prevData => {
            const newData = [...prevData];
            newData[index].attribute_values = attributeValues;
            return newData;
        });
    };

    const handleImageChange = (e) => {
        const fileList = e.target.files;
        const newImages = [];

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const reader = new FileReader();

            reader.onload = () => {
                newImages.push(reader.result);
                if (newImages.length === fileList.length) {
                    setImages(newImages);
                }
            };

            reader.readAsDataURL(file);
        }
    };
    const handleDeleteImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };


    useEffect(() => {
        getBrands();
        getProductTypes();
        getAttributeSets();
        getCategories();
    }, []);
    return (
        <div class="container-fluid">
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/products"
                    >
                        Products
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                        to="#"
                    >
                        Add Product
                    </Link>
                </Breadcrumbs>
                <hr></hr>
            </div>
            <div class="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <form method="POST" className="product-form">
                            <h3 className="edit-title">Add Product <i class="fi fi-rr-add"></i> </h3>
                            <hr></hr>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SelectSearch options={productTypes} onChange={setProductTypeId} value={productTypeId} search placeholder="Choose Product Type" />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectSearch options={products} value={parentId} onChange={(e) => setParentId(e)} search placeholder="Choose your Parent Product" getOptions={(query) => {

                                        return new Promise((resolve, reject) => {
                                            let formdata = new FormData();
                                            formdata.append("search", query);
                                            axios("admin/config_product_list_by_keyword", {
                                                method: "POST",
                                                data: formdata,
                                                headers: {
                                                    "Content-Type": "application/x-www-form-urlencoded",
                                                    Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
                                                },
                                            })
                                                .then((response) => {
                                                    response.data.data.unshift({ name: 'No parent', value: '0' });
                                                    setProducts(response.data.data);

                                                })
                                                .catch(reject);

                                        });
                                    }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectSearch options={brands} onChange={setBrandId} value={brandId} search placeholder="Choose Brand" />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectSearch options={attributeSets} onChange={handleChangeAttributeSet} value={attributeSetId} search placeholder="Choose Attribute Set" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        isMulti
                                        options={categories}
                                        value={categoryId}
                                        onChange={setCategoryId}
                                        placeholder="Select Categories"
                                        isSearchable
                                        closeMenuOnSelect={false}
                                        blurInputOnSelect={false}
                                    />
                                    {/* <SelectSearch options={categories} multiple onChange={setCategoryId} value={categoryId} closeOnSelect search placeholder="Choose Category" /> */}
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setName(e.target.value)} value={name} name="name" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setNameAr(e.target.value)} value={nameAr} name="name_ar" />
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="label-description">
                                        Description<br></br>
                                        <TextEditor defaultValue={description} onChange={(e) => setDescription(e)} />
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="label-description">
                                        Description Arabic<br></br>
                                        <TextEditor defaultValue={descriptionAr} onChange={(e) => setDescriptionAr(e)} />
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="label-description">
                                        Short Description<br></br>
                                        <textarea className="description" rows={4} cols={40} onChange={(e) => setShortDescription(e.target.value
                                        )} value={shortDescription} name="short_description" />
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="label-description">
                                        Short Description Arabic<br></br>
                                        <textarea className="description" rows={4} cols={40} onChange={(e) => setShortDescriptionAr(e.target.value
                                        )} value={shortDescriptionAr} name="short_description_ar" />
                                    </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="outlined-basic" fullWidth label="Price" variant="outlined" onChange={(e) => setPrice(e.target.value)} value={price} name="price" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="outlined-basic" fullWidth label="Special Price" variant="outlined" onChange={(e) => setSpecialPrice(e.target.value)} value={specialPrice} name="special_price" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="outlined-basic" fullWidth label="Qty" variant="outlined" onChange={(e) => setQty(e.target.value)} value={qty} name="qty" />
                                </Grid>
                                <Grid item xs={11}>
                                    {
                                        attributes.map((attribute, index) => {
                                            return (
                                                <AttributeSelector key={index} attribute={attribute} attributeValues={attribute.attribute_values} removeElement={removeElement} index={index} handleAttributeSelect={handleAttributeSelect} handleUpdateAttributeValues={handleUpdateAttributeValues}> </AttributeSelector>
                                            )
                                        })
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <span onClick={addElement} className="btn btn-primary"><i class="fa fa-plus"></i> Add</span>
                                </Grid>
                                <Grid item xs={6} >
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload
                                        <VisuallyHiddenInput type="file" multiple  onChange={handleImageChange} />
                                    </Button>

                                </Grid>
                                <Grid container spacing={2}>

                                    {images.map((image, index) => {
                                        return (
                                            <Grid key={index} item xs={3} >
                                                <img height={100} src={image} alt={`Image ${index}`} />
                                                <span className="btn btn-sm btn-danger" title="Delete" onClick={() => handleDeleteImage(index)}><i className="fi fi-rr-trash"></i></span>
                                            </Grid>
                                        );
                                    })}


                                </Grid>

                                <Grid item xs={12}>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary"><i class="fi fi-rr-layer-plus"></i> Add</button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <form method="POST" className="product-form">
                            <h3 className="edit-title">Add SEO Contents <i class="fi fi-rr-add"></i></h3>
                            <hr></hr>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField id="outlined-basic" fullWidth label="Meta Title" variant="outlined" onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} />
                                </Grid>
                                <Grid item xs={12}>
                                    <label className="label-description">
                                        Meta Description<br></br>
                                        <textarea className="description" rows={4} cols={40} onChange={(e) => setMetaDescription(e.target.value
                                        )} value={metaDescription} />
                                    </label>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="outlined-basic" fullWidth label="Meta Keyword" variant="outlined" onChange={(e) => setMetaKeyword(e.target.value)} value={metaKeyword} />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* <button type="button" onClick={handleSubmit} className="btn btn-primary"><i class="fi fi-rr-layer-plus"></i> Add</button> */}
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default AddProduct;