import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory, Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { ErrorHandler } from "../../ui/ErrorHandler";
import TextEditor from "../../ui/TextEditor";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import "./Product.css";
import Button from '@mui/material/Button';
import ImageEditor from "../../components/ImageEditor";
import { styled } from '@mui/material/styles';
import AttributeSelectorEdit from "../../components/AttributeSelectorEdit";
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


function EditProduct(props) {
    const alert = useAlert();
    const history = useHistory();
    const [products, setProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [attributeSets, setAttributeSets] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [product, setProduct] = useState({});
    const [productImages, setProductImages] = useState([]);
    const [seo, setSeo] = useState({});
    const [productId, setProductId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    useEffect(() => {
        getProductDetails();
        getCategories();
        getAttributeSets();
        getProductTypes();
        getProductImages();
        getBrands();
    }, [props.match.params.id]);
    const getProductDetails = () => {
        axios(`admin/products/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setProduct(response.data.data.product);
                    setCategoryId(response.data.data.product.categories);
                    setAttributes(response.data.data.product.attributes)
                    response.data.data.product.attributes.forEach(val => {
                        addKeyValuePair(val.id, val.pivot.attribute_value_id);
                    });

                    setSeo(response.data.data.seo[0] ?? {});
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };


    const getCategories = () => {
        axios("admin/category_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setCategories(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };

    const getBrands = () => {
        axios("admin/brand_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setBrands(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };


    const getProductTypes = () => {
        axios("admin/product_type_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setProductTypes(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };

    const getProductImages = () => {
        let formdata = new FormData();
        formdata.append("product_id", props.match.params.id);
        axios("admin/product_images", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setProductImages(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };

    const addKeyValuePair = (key, value) => {
        setSelectedAttributes(prevObject => ({
            ...prevObject,
            [key]: value
        }));
    };

    const getAttributeSets = () => {
        axios("admin/attribute_set_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAttributeSets(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };


    const handleChangeAttributeSet = (e) => {
        setProduct({
            ...product,
            attribute_set_id: e,
        })
        let formdata = new FormData();
        formdata.append("attribute_set_id", e);
        axios("admin/attribute_by_attribute_set_id", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAttributes(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };


    const handleImageChange = (e) => {
        const fileList = e.target.files;
        const newImages = [];

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const reader = new FileReader();

            reader.onload = () => {
                newImages.push(reader.result);
                if (newImages.length === fileList.length) {
                    setImages(newImages);
                }
            };

            reader.readAsDataURL(file);
        }
    };
    const handleDeleteImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };
    const removeElement = (index) => {
        setAttributes(prevItems => prevItems.filter((item, i) => i !== index));
        const newAttributes = { ...selectedAttributes };
        delete newAttributes[attributes[index].id];
        setSelectedAttributes(newAttributes);

    }
    const addElement = () => {
        const newData = [...attributes];
        newData.push({ id: 0, name: '', checked: 0, attribute_values: [] });
        setAttributes(newData);
    }

    const handleAttributeSelect = (attributeId, value) => {
        setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: value
        });
    };

    const handleUpdateAttributeValues = (index, attributeValues) => {
        setAttributes(prevData => {
            const newData = [...prevData];
            newData[index].attribute_values = attributeValues;
            return newData;
        });
    };


    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", product.name);
        formdata.append("name_ar", product.name_ar);
        formdata.append("slug", product.slug);
        formdata.append("price", product.price);
        formdata.append("special_price", product.special_price);
        formdata.append("qty", product.qty);
        formdata.append("parent_id", product.parent_id);
        formdata.append("description", product.description);
        formdata.append("description_ar", product.description_ar);
        formdata.append("short_description", product.short_description);
        formdata.append("short_description_ar", product.short_description_ar);
        formdata.append("selected_attributes", JSON.stringify(selectedAttributes));
        formdata.append("brand_id", product.brand_id);
        formdata.append("category_ids", categoryId.map(item => item.value).join(','));
        formdata.append("product_type_id", product.product_type_id);
        formdata.append("attribute_set_id", product.attribute_set_id);
        formdata.append("meta_title", seo.meta_title);
        formdata.append("meta_description", seo.meta_description);
        formdata.append("meta_keyword", seo.meta_keyword);
        formdata.append("store_id", 1);
        formdata.append("lang_id", 1);
        formdata.append("id", productId);
        for (const img of images) {
            formdata.append('images[]', dataURItoBlob(img));
        }
        axios("admin/product_update", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    history.push({
                        pathname: "/products",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    return (
        <div class="container-fluid">
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/products"
                    >
                        Products
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        to="#"
                        aria-current="page"
                    >
                        Edit Product
                    </Link>
                </Breadcrumbs>
                <hr></hr>
            </div>
            <div class="container-fluid  mt-5">
                <div className="row">
                    <div className="col-md-8">
                        {Object.keys(product).length != 0 && (
                            <form method="POST" className="product-form">
                                <h3 className="edit-title">Edit Product <i class="fi fi-rr-file-edit"></i></h3>
                                <hr></hr>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <SelectSearch options={productTypes} onChange={(e) => setProduct({
                                            ...product,
                                            product_type_id: e,
                                        })} value={product.product_type_id} search placeholder="Choose Product Type" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectSearch options={products} value={product.parent_id} onChange={(e) => setProduct({
                                            ...product,
                                            parent_id: e,
                                        })} search placeholder="Choose your Parent Product" getOptions={(query) => {

                                            return new Promise((resolve, reject) => {
                                                let formdata = new FormData();
                                                formdata.append("search", query);
                                                axios("admin/config_product_list_by_keyword", {
                                                    method: "POST",
                                                    data: formdata,
                                                    headers: {
                                                        "Content-Type": "application/x-www-form-urlencoded",
                                                        Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
                                                    },
                                                })
                                                    .then((response) => {
                                                        response.data.data.unshift({ name: 'No parent', value: '0' });
                                                        setProducts(response.data.data);

                                                    })
                                                    .catch(reject);

                                            });
                                        }} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SelectSearch options={brands} onChange={(e) => setProduct({
                                            ...product,
                                            brand_id: e,
                                        })} value={product.brand_id} search placeholder="Choose Brand" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SelectSearch options={attributeSets} onChange={handleChangeAttributeSet} value={product.attribute_set_id} search placeholder="Choose Attribute Set" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Select
                                            isMulti
                                            options={categories}
                                            value={categoryId}
                                            onChange={setCategoryId}
                                            placeholder="Select Categories"
                                            isSearchable
                                            closeMenuOnSelect={false}
                                            blurInputOnSelect={false}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setProduct({
                                            ...product,
                                            name: e.target.value,
                                        })} value={product.name} name="name" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setProduct({
                                            ...product,
                                            name_ar: e.target.value,
                                        })} value={product.name_ar} name="name_ar" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Description<br></br>
                                            <TextEditor defaultValue={product.description} onChange={(e) => setProduct({
                                                ...product,
                                                description: e,
                                            })} />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Description Arabic<br></br>
                                            <TextEditor defaultValue={product.description_ar} onChange={(e) => setProduct({
                                                ...product,
                                                description_ar: e,
                                            })} />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Short Description<br></br>
                                            <textarea className="description" rows={4} cols={40} onChange={(e) => setProduct({
                                                ...product,
                                                short_description: e.target.value,
                                            })} value={product.short_description} name="short_description" />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Short Description Arabic<br></br>
                                            <textarea className="description" rows={4} cols={40} onChange={(e) => setProduct({
                                                ...product,
                                                short_description_ar: e.target.value,
                                            })} value={product.short_description_ar} name="short_description_ar" />
                                        </label>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Price" variant="outlined" onChange={(e) => setProduct({
                                            ...product,
                                            price: e.target.value,
                                        })} value={product.price} name="price" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Special Price" variant="outlined" onChange={(e) => setProduct({
                                            ...product,
                                            special_price: e.target.value,
                                        })} value={product.special_price} name="special_price" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Qty" variant="outlined" onChange={(e) => setProduct({
                                            ...product,
                                            qty: e.target.value,
                                        })} value={product.qty} name="qty" />
                                    </Grid>

                                    <ImageEditor getProductImages={getProductImages} productImages={productImages}>

                                    </ImageEditor>
                                    <Grid item xs={6} >
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload
                                            <VisuallyHiddenInput multiple type="file"  onChange={handleImageChange} />
                                        </Button>

                                    </Grid>
                                    <Grid container spacing={2}>

                                        {images.map((image, index) => {
                                            return (
                                                <Grid key={index} item xs={3} >
                                                    <img height={100} src={image} alt={`Image ${index}`} />
                                                    <span className="btn btn-sm btn-danger" title="Delete" onClick={() => handleDeleteImage(index)}><i className="fi fi-rr-trash"></i></span>
                                                </Grid>
                                            );
                                        })}


                                    </Grid>

                                    <Grid item xs={11}>
                                        {
                                            attributes.map((attribute, index) => {
                                                return (
                                                    <AttributeSelectorEdit key={index} attribute={attribute} attributeValues={attribute.attribute_values} removeElement={removeElement} index={index} handleAttributeSelect={handleAttributeSelect} handleUpdateAttributeValues={handleUpdateAttributeValues}> </AttributeSelectorEdit>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <Grid item xs={1}>
                                        <span onClick={addElement} className="btn btn-primary"><i class="fa fa-plus"></i> Add</span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </div>
                    <div className="col-md-4">
                        {Object.keys(seo).length != 0 && (
                            <form method="POST" className="product-form">
                                <h3 className="edit-title"> SEO Contents  <i class="fi fi-rr-file-edit"></i> </h3>
                                <hr></hr>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField id="outlined-basic" fullWidth label="Seo url" variant="outlined" onChange={(e) => setProduct({
                                            ...product,
                                            slug: e.target.value,
                                        })} value={product.slug} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="outlined-basic" fullWidth label="Meta Title" variant="outlined" onChange={(e) => setSeo({
                                            ...seo,
                                            meta_title: e.target.value,
                                        })} value={seo.meta_title} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="label-description">
                                            Meta Description<br></br>
                                            <textarea className="description" rows={4} cols={40} onChange={(e) => setSeo({
                                                ...seo,
                                                meta_description: e.target.value,
                                            })} value={seo.meta_description} />
                                        </label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="outlined-basic" fullWidth label="Meta Keyword" variant="outlined" onChange={(e) => setSeo({
                                            ...seo,
                                            meta_keyword: e.target.value,
                                        })} value={seo.meta_keyword} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <button type="button" onClick={handleSubmit} className="btn btn-primary"><i class="fi fi-rr-layer-plus"></i> Add</button> */}
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EditProduct;